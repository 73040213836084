<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">首页</a>
        </span>
      </div>
      <div class="framePage-body my-body" style="background: #f5f5f6">
        <div class="body-top" ref="body-top">
          <!-- 上部左侧统计 -->
          <div class="panel">
            <div class="panel-item blue">
              <div class="tit">学员情况</div>
              <div class="content">
                <div class="left">
                  <div>学员总数</div>
                  <div>{{ studentStatic.studentNum }}</div>
                </div>
                <div class="right">
                  <div>今日新增</div>
                  <div>{{ studentStatic.studentToday }}</div>
                </div>
              </div>
            </div>
            <div class="panel-item green">
              <div class="tit">培训情况</div>
              <div class="content">
                <div class="left">
                  <div>培训总数</div>
                  <div>{{ studentStatic.trainNum }}</div>
                </div>
                <div class="right">
                  <div>今日新增</div>
                  <div>{{ studentStatic.trainToday }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 上部左侧统计 end-->
          <!-- 上部左侧列表 -->
          <div class="table">
            <div class="tittab">今日报名情况</div>
            <el-table
              ref="multipleTable"
              :data="tableData"
              height="calc(100% - 41px)"
              size="small"
              tooltip-effect="dark"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="80"
              />
              <el-table-column
                label="培训名称"
                align="center"
                show-overflow-tooltip
                prop="projectName"
              />
              <el-table-column
                label="开始时间"
                align="center"
                show-overflow-tooltip
                prop="startDate"
              />
              <el-table-column
                label="结束时间"
                align="center"
                show-overflow-tooltip
                prop="endDate"
              />
              <el-table-column
                label="报名人数"
                align="center"
                show-overflow-tooltip
                prop="projectPeople"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
          <!-- 上部左侧列表 end-->
        </div>
        <div class="body-bottom">
          <div class="titbot">
            <div>近期报名情况</div>
            <div>
              <el-date-picker
                v-model="searchForm.time"
                type="daterange"
                align="right"
                size="small"
                range-separator="至"
                @change="timechange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="chart" ref="chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "institutionalManagement/home",
  components: {
    Empty,
  },
  mixins: [List],
  data() {
    return {
      charts: null,
      studentStatic: {
        studentNum: "533",
        studentToday: "0",
        trainNum: "0",
        trainToday: "0",
      },
      searchForm: {
        time: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = "";
          }
        },
        disabledDate: (time) => {
          let choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setMonth(
            new Date(choiceDateTime).getMonth() - 3
          );
          const maxTime = new Date(choiceDateTime).setMonth(
            new Date(choiceDateTime).getMonth() + 3
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() +
            24 * 60 * 60 * 1000 -
            1;
          const max = newDate < maxTime ? newDate : maxTime;
          //如果已经选中一个日期 则 返回 该日期前后三个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        },
      },
      echartData: [],
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  created() {
    let end = new Date();
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = this.$moment(start).format("YYYY-MM-DD");
    end = this.$moment(end).format("YYYY-MM-DD");
    this.searchForm.time = [start, end];
    this.getEchartData();
  },
  mounted() {
    this.getStudentStatic();
  },
  methods: {
    getData() {
      this.$post("/biz/ct/home/todayApplyList").then((res) => {
         if(res.data.length<5){
            this.tableData = [...res.data , ...new Array(5-res.data.length).fill({})];
         }else if(res.data.length==5){
            this.tableData = res.data
         }else{
            this.tableData = res.data.slice(0,5);
         }
      });
    },
    // 获取统计数据
    getStudentStatic() {
      this.$post("/biz/ct/home/studentStatic").then((res) => {
         this.studentStatic = res.data
      });
    },
    // 获取echarts数据
    getEchartData() {
      if (!this.searchForm.time) {
        this.$message.error("请选择日期");
        return;
      }
      let data = {
        compId: this.userJson.compId,
        endDate: this.searchForm.time[1],
        startDate: this.searchForm.time[0],
      };
      this.$post("/biz/ct/home/applyStatic", data)
        .then((res) => {
          this.echartData = res.data;
          this.echartInit();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    timechange() {
      this.getEchartData();
    },
    //初始echart
    echartInit() {
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.echartData.map((e) => e.date),
        },
        tooltip: {},
        grid: {
          x: "40px",
          top: "10px",
          bottom: "30px",
          right: "20px",
        },
        yAxis: {
          type: "value",
          minInterval:1
        },
        series: [
          {
            data: this.echartData.map((e) => e.studentNum),
            type: "line",
            areaStyle: {},
            smooth: true,
          },
        ],
      };
      if (!this.charts) {
        this.charts = this.$echarts.init(this.$refs.chart);
        this.charts.setOption(option);
      } else {
        this.charts.setOption(option, { notMerge: true });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.pageContol {
  padding: 0 10px;
}
.my-body {
  padding: 10px 0;
  justify-content: space-between;
  .body-top {
    height: 40%;
    display: flex;
    align-items: stretch;
    .panel {
      width: 18%;
      min-width: 200px;
      max-width: 300px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      :first-child.panel-item {
        margin-bottom: 20px;
      }
      .panel-item {
        color: #ffffff;
        text-align: left;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 12px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.blue {
          background: #68acef;
        }
        &.green {
          background: #5ad3a2;
        }
        .tit {
          font-size: 16px;
        }
        .content {
          clear: both;
          .left {
            float: left;
            width: 50%;
            text-align: left;
            :first-child {
              font-size: 14px;
            }
            :last-child {
              font-size: 24px;
              line-height: 2em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .right {
            float: right;
            width: 50%;
            text-align: right;
            :first-child {
              font-size: 14px;
            }
            :last-child {
              font-size: 24px;
              line-height: 2em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .table {
      width: 82%;
      background: #fff;
      border-radius: 12px;
      padding: 20px;
      .tittab {
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        margin-bottom: 15px;
      }
      /deep/ .el-table td.el-table__cell,
      /deep/ .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
      /deep/.el-table::before {
        height: 0;
      }
    }
  }
  .body-bottom {
    height: 58%;
    padding: 22px 20px;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    .titbot {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      color: #666666;
      margin-bottom: 15px;
    }
    .chart {
      flex: 1;
    }
  }
}
</style>